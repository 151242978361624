/* eslint-disable react-hooks/exhaustive-deps */
import "./gallerySlider.css";

import React from "react";
import { IconButton } from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";

import SectionFadeIn from "../general/SectionFadeIn";


const GallerySlider = ({ images, preText=null }) => {

  const [activeImage, setActiveImage] = React.useState(0);

  //Auto rotate images
  const [intervalItem, setIntervalItem] = React.useState(null);
  React.useEffect(() => {
    if (intervalItem) {
      clearInterval(intervalItem);
    }
    setIntervalItem(setInterval(() => {
      setActiveImage((activeImage + 1) % images.length);
    }, 7000));
    return () => {
      clearInterval(intervalItem);
    };
  }, [activeImage]);

  //Clear interval when manually changing image
  const manuallyChangeImage = (index) => {
    clearInterval(intervalItem);
    setActiveImage(index);
  };


  return (
    <SectionFadeIn className={"standard-width gallery-slider"}>
      {
        (preText) && (
          <p
            className={"gallery-slider-pre"}
            dangerouslySetInnerHTML={{ __html: preText }}
          />
        )
      }
      <div className={"gallery-slider-img-cont"}>
        {
          images.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`gallery-slider-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </div>
      <div className={"gallery-slider-nav"}>
        <IconButton onClick={() => manuallyChangeImage((activeImage - 1 + images.length) % images.length)}>
          <ArrowBack />
        </IconButton>
        <p>
          {activeImage + 1} / {images.length}
        </p>
        <IconButton onClick={() => manuallyChangeImage((activeImage + 1) % images.length)}>
          <ArrowForward />
        </IconButton>
      </div>
    </SectionFadeIn>
  );
};

export default GallerySlider;

