/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-max-props-per-line */
import "./footer.css";

import React from "react";
import { Button } from "@material-ui/core";


const Footer = () => {
  return (
    <footer>
      <div className={"standard-width"}>
        <div className={"footer-socials"}>
          <Button
            href={"https://www.facebook.com/chezcharleneweddingvenue"}
            target={"_blank"}
          >
            <img
              className={"footer-social-icon"}
              src={"/images/facebook-logo.png"}
            />
          </Button>
          <Button
            href={"https://www.instagram.com/chezcharlene.wedding.venue/"}
            target={"_blank"}
          >
            <img
              className={"footer-social-icon"}
              src={"/images/instagram-logo.png"}
            />
          </Button>
          <Button
            href={"https://wa.me/+27609580350"}
            target={"_blank"}
          >
            <img
              className={"footer-social-icon"}
              src={"/images/whatsapp-logo.png"}
            />
          </Button>
        </div>

        <p className={"footer-contact"}>
          <a href={"tel:060958 0350"}>060 958 0350</a> | <a href={"tel:0128020791"}>012 802 0791</a> | <a href={"mailto:info@chezcharlene.co.za"}>info@chezcharlene.co.za</a>
        </p>

        <img
          className={"footer-logo"}
          src={"/images/logo-white.png"}
        />

        <p className={"footer-legal"}>
          Site built by <a href={"https://tap-x.com"} target={"_blank"}>TapX</a> | Icons by <a href={"https://www.flaticon.com/authors/freepik"} target={"_blank"}>Freepik</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

