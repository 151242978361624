import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

/* ======================================== COMPONENT ======================================== */

const SectionFadeIn = ({ children, ...props }) => {
  return (
    <ScrollAnimation
      animateIn={"fadeIn"}
      animateOnce={true}
      duration={2}
    >
      <section {...props}>
        {children}
      </section>
    </ScrollAnimation>
  );
};

export default SectionFadeIn;