import "./contact.css";

import React from "react";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import { LOADING_STATE } from "../../utils/stateTypes";
import SectionFadeIn from "../general/SectionFadeIn";

const styles = {
  blockButton: {
    width: 200,
  },
  bookingInput: {
    marginBottom: 20,
  },
};

const sendMessage = async (name, email, date, phone, pax, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      date,
      type: "Requesting a viewing",
      phone,
      pax,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};

const Contact = () => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [date, setDate] = React.useState(null);
  const [pax, setPax] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Submit";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  return (
    <SectionFadeIn className={"standard-width booking-section"}>
      <img
        className={"booking-img"}
        src={"/images/home/home-info-8.jpg"}
      />
      <form
        className={"booking-content"}
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(name, email, date, phone, pax, setSendState);
        }}
      >
        <img
          src={"/images/plane.png"}
          className={"booking-pre-head"}
        />
        <h2 className={"section-head"}>Book a Viewing</h2>
        <p>We'll get in touch with you to arrange a date & time.</p>
        <TextField
          label={"Name"}
          style={styles.bookingInput}
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label={"Email"}
          style={styles.bookingInput}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label={"Phone"}
          style={styles.bookingInput}
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <DatePicker
          label={"Event Date"}
          variant={"dialog"}
          DialogProps={{
            disableScrollLock: true,
          }}
          style={styles.bookingInput}
          fullWidth
          value={date}
          onChange={(e) => setDate(e)}
        />
        <TextField
          label={"Approximate Number of Guests"}
          style={styles.bookingInput}
          fullWidth
          value={pax}
          onChange={(e) => setPax(e.target.value)}
        />
        <Button
          variant={"outlined"}
          color={"primary"}
          type={"submit"}
          style={styles.blockButton}
          disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
        >
          {submitLabel}
        </Button>
      </form>
    </SectionFadeIn>
  );
};

export default Contact;

