import "./venue.css";

import React from "react";
import { Helmet } from "react-helmet";

import SectionFadeIn from "../general/SectionFadeIn";
// import { PAGE_ROUTES } from "../../utils/stateTypes";
// import Header from "../sections/Header";
// import GalleryBlock from "../sections/GalleryBlock";
import Contact from "../sections/Contact";
import GallerySlider from "../sections/GallerySlider";
import InfoBlocks from "../sections/InfoBlocks";
import Showcase from "../sections/Showcase";

const BANNER_IMAGES = [
  "/images/venue/acc-banner-1.jpg",
];

const Accommodation = () => {

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Accommodation | Chez Charlene</title>
        <meta
          name={"description"}
          content={"Chez Charlene offers an exquisite luxury accommodation experience, nestled in the serene outskirts of Pretoria, with a honeymoon suite that promises an unforgettable romantic retreat. The suite exudes elegance and comfort, providing newlyweds with a private sanctuary to celebrate their union."}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Accommodation" },
        ]}
        head={"Accommodation"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>A celestial haven where<br/>slumber paints the skies</h1>
          <p>Discover our range of perfect venues that meet every requirement</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width venue-heading"}>
        <h1>Heavenly Accommodation</h1>
      </SectionFadeIn>

      <GallerySlider
        images={[
          "/images/venue/acc-slider-1.jpg",
          "/images/venue/acc-slider-2.jpg",
          "/images/venue/acc-slider-3.jpg",
        ]}
        preText={`
          Chez Charlene 5 Star Wedding Venue, Pretoria, Gauteng has a total of seven rooms on the property which is split between the Main and 
          Boutique Venue. We are affiliated with several Guesthouses near Chez Charlene with more than enough rooms to accommodate guests! We 
          can also arrange shuttle services at an additional cost to ensure the comfort and security of your guests.
        `}
      />

      <InfoBlocks
        head={"Serene & Sophisticated"}
        blocks={[
          {
            head: "Main Venue Rooms",
            img: "/images/venue/acc-gal-8.jpg",
            text1:  `At the Main Venue, we have four double rooms that can cater for a total
            of eight guests. This includes the Honeymoon Suite for the night of your wedding,
            which is included when you book at the Main Venue.`,
            text2: "4 Rooms sleeping 8 guests",
          },
          {
            head: "Boutique Rooms",
            img: "/images/venue/acc-gal-9.jpg",
            text1: `At the Boutique venue, we have three double rooms that can cater for a
            total of six guests. This includes the Honeymoon suite for the night of your
            wedding when you book the Boutique Venue. These rooms are usually booked for the
            bride and groom’s families.`,
            text2: "3 Rooms sleeping 6 guests",
          },
          {
            head: "Honeymoon Suites",
            img: "/images/venue/acc-gal-10.jpg",
            text1: `The honeymoon suites promise an unforgettable romantic retreat. The
            suites exude elegance and comfort, providing newlyweds with a private sanctuary
            to celebrate their union`,
            text2: "A romantic retreat",
          },
        ]}
      />

      {/* <SectionFadeIn className={"standard-width gallery-section"}>
        <GalleryBlock
          image1={"/images/venue/acc-gal-3.jpg"}
          image2={"/images/venue/acc-gal-1.jpg"}
          image3={"/images/venue/acc-gal-2.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/venue/acc-gal-6.jpg"}
          image2={"/images/venue/acc-gal-4.jpg"}
          image3={"/images/venue/acc-gal-5.jpg"}
          isRight={true}
        />
      </SectionFadeIn> */}

      {/* <SectionFadeIn className={"standard-width"}>
        <div className={"home-collage"}>
          <img src={"/images/home/home-collage-2.jpg"} />
        </div>
      </SectionFadeIn> */}

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/venue/acc-gal-6.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default Accommodation;

