import "./showcase.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const styles = {
  infoButton: {
    marginTop: 20,
    padding: "10px 20px",
  },
};


const Showcase = ({ preHead=null, head1, head2=null, text, link=null, linkLabel, linkHref=null, image, isGold=false, swapped=false }) => {

  const animName = (swapped) ? "backInRight" : "backInLeft";

  return (
    <div className={"home-showcase"}>
      {
        (swapped) && (
          <img
            src={image}
            className={"home-showcase-img"}
          />
        )
      }
      {
        (!swapped) && (
          <img
            src={image}
            className={"home-showcase-img show-when-sm"}
          />
        )
      }
      <div className={`home-showcase-content ${(isGold) ? "gold" : "white"}`}>
        {
          (preHead !== null) && (
            <ScrollAnimation
              animateIn={animName}
              animateOnce={true}
              duration={1}
            >
              <p className={"section-head-pre"}>{preHead}</p>
            </ScrollAnimation>
          )
        }
        <h2 className={"section-head"}>
          <ScrollAnimation
            animateIn={animName}
            animateOnce={true}
            duration={1}
            delay={200}
          >
            {head1}
            {
              (head2) && (
                <React.Fragment>
                  <br />
                  {head2}
                </React.Fragment>
              )
            }
          </ScrollAnimation>
        </h2>
        <ScrollAnimation
          animateIn={animName}
          animateOnce={true}
          duration={1}
          delay={400}
        >
          <p className={"home-showcase-text"}>{text}</p>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn={animName}
          animateOnce={true}
          duration={1}
          delay={600}
        >
          {
            (link) && (
              <Link to={link}>
                <Button
                  variant={"outlined"}
                  style={styles.infoButton}
                  color={(isGold) ? "secondary" : "primary"}
                >
                  {linkLabel}
                </Button>
              </Link>
            )
          }
          {
            (linkHref) && (
              <Button
                variant={"outlined"}
                style={styles.infoButton}
                color={(isGold) ? "secondary" : "primary"}
                href={linkHref}
                target={"_blank"}
              >
                {linkLabel}
              </Button>
            )
          }
        </ScrollAnimation>
      </div>
      {
        (!swapped) && (
          <img
            src={image}
            className={"home-showcase-img hide-when-sm"}
          />
        )
      }
    </div>
  );
};

export default Showcase;

