import "./venue.css";

import React from "react";
import { Helmet } from "react-helmet";

import SectionFadeIn from "../general/SectionFadeIn";
// import { PAGE_ROUTES } from "../../utils/stateTypes";
// import Header from "../sections/Header";
import Contact from "../sections/Contact";
import GallerySlider from "../sections/GallerySlider";
import InfoBlocks from "../sections/InfoBlocks";
// import GalleryBlock from "../sections/GalleryBlock";
import Showcase from "../sections/Showcase";

const BANNER_IMAGES = [
  "/images/venue/bv-banner-1.jpg",
];

const VenueBoutique = () => {

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Boutique Venue | Chez Charlene</title>
        <meta
          name={"description"}
          content={"This chapel, surrounded by lush gardens, seats up to 100 guests with all standard décor included. Perfect for your intimate wedding ceremony and reception."}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Boutique Venue" },
        ]}
        head={"Boutique Venue"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Don’t you know, it’s your<br/>light that lights our world</h1>
          <p>Discover our range of perfect venues that meet every requirement</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width venue-heading"}>
        <h1>A Unique, Boutique Experience</h1>
      </SectionFadeIn>

      <GallerySlider
        images={[
          "/images/venue/boutique-new-8.jpg",
          "/images/venue/boutique-new-10.jpg",
          "/images/venue/boutique-new-7.jpg",
        ]}
        preText={`
          Chez Charlene 5 Star Intimate Boutique Venue, Pretoria, Gauteng is an idyllic intimate venue for your smaller weddings, cocktail parties, 
          birthdays, anniversaries, corporate functions, or any other special occasions. It is stylishly intimate and comfortably seats up to 100 
          guests including an undercover dance floor on the outside patio. It offers pristine gardens with a warm and friendly atmosphere. Other 
          features include a climate-controlled venue and chapel, full bar facilities, secure parking, 5-star catering, a backup generator and much more.
        `}
      />

      <InfoBlocks
        head={"Blissful & Private"}
        blocks={[
          {
            head: "Chapel",
            img: "/images/venue/boutique-new-6.jpg",
            text1:  `The Boutique venue chapel seats 100 guests, with all standard décor included
            in your personalised quotation. The chapel can only be described as chic elegance
            with specialised technical sound equipment and air conditioners that are managed
            by our in-house sound engineer. Natural light enhances the ambience enabling
            photographers to create celestial, artful memories of your wedding day.`,
            text2: "Gorgeous natural light",
          },
          {
            head: "Reception",
            img: "/images/venue/boutique-new-9.jpg",
            text1: `The Boutique reception venue embodies the sophisticated elegance of
            French style. The venue is enveloped by water ponds, which have been thoughtfully
            arranged in an artistic manner to create a stunning visual effect. The tranquil
            sound of running water adds a serene ambience to the space, making it the ideal
            location for your special event.`,
            text2: "French-styled elegance",
          },
          {
            head: "Gardens",
            img: "/images/venue/boutique-new-11.jpg",
            text1: `Upon entering the Boutique Venue guests are immediately greeted by the
            breathtaking beauty of the water features that surround the venue. The ponds are
            surrounded by lush greenery and blooms creating a picturesque backdrop for any
            occasion. The architecture of the venue is designed to complement the natural
            surroundings, with sleek lines and subtle curves that add to the overall
            sophistication of the space.`,
            text2: "Perfect photographic moments",
          },
        ]}
      />

      {/* <SectionFadeIn className={"standard-width gallery-section"}>
        <GalleryBlock
          image1={"/images/venue/bv-gal-1.jpg"}
          image2={"/images/venue/bv-gal-4.jpg"}
          image3={"/images/venue/bv-gal-2.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/venue/bv-gal-5.jpg"}
          image2={"/images/venue/bv-gal-3.jpg"}
          image3={"/images/venue/bv-gal-6.jpg"}
          isRight={true}
        />
      </SectionFadeIn> */}

      <SectionFadeIn className={"standard-width"}>
        <div className={"home-collage"}>
          <img src={"/images/venue/boutique-collage-1.jpg"} />
        </div>
      </SectionFadeIn>

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/venue/boutique-new-4.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default VenueBoutique;

