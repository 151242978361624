import "./venue.css";

import React from "react";
import { Helmet } from "react-helmet";

import SectionFadeIn from "../general/SectionFadeIn";
// import { PAGE_ROUTES } from "../../utils/stateTypes";
// import Header from "../sections/Header";
import Contact from "../sections/Contact";
import GallerySlider from "../sections/GallerySlider";
import InfoBlocks from "../sections/InfoBlocks";
// import GalleryBlock from "../sections/GalleryBlock";
import Showcase from "../sections/Showcase";

const BANNER_IMAGES = [
  "/images/venue/corp-banner-1.jpg",
];

const VenueCorporate = () => {

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Corporate Events | Chez Charlene</title>
        <meta
          name={"description"}
          content={"With 5-star hospitality and service, our Corporate Venue has been awarded the best venue in Gauteng. An oasis of beauty and tranquility, it is the perfect backdrop for your corporate event. 10 - 350 guests"}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Corporate Events" },
        ]}
        head={"Corporate Events"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Today’s innovation<br/>is tomorrow’s tradition</h1>
          <p>Discover our range of perfect venues that meet every requirement</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width venue-heading"}>
        <h1>Driven and Passionate</h1>
      </SectionFadeIn>

      <GallerySlider
        images={[
          "/images/venue/corp-slider-1.jpg",
          "/images/venue/corp-slider-2.jpg",
          "/images/venue/corp-slider-3.jpg",
        ]}
        preText={`
          Whether you are planning a business conference, seminar, training session, product launch, or private event, Chez Charlene 
          Conference Centre has all the amenities you need to make your event a success. Chez Charlene Conference Centre is a versatile and modern venue 
          located in a picturesque setting that caters to events ranging from 20 to 350 guests. The venue is spacious and well-equipped with comfortable 
          seating, air conditioning, and ample natural lighting. 
        `}
      />

      <InfoBlocks
        head={"World-Class Facilities"}
        blocks={[
          {
            head: "Main Venue",
            img: "/images/venue/corp-gal-9.jpg",
            text1:  `Package includes: Full PA system, projector and screen, sound engineer,
            arrival snacks, tea and coffee, morning and afternoon snacks, lunch, notepads and
            pens, Set-up & Service fee, secure parking and a function manager.`,
            text2: "20 to 350 guests",
          },
          {
            head: "Boutique Venue",
            img: "/images/venue/corp-gal-4.jpg",
            text1: `Package includes: Full PA system, sound engineer, arrival snacks with tea
            and coffee, mid-morning and mid-afternoon snacks, specially designed lunch, note
            pads and pens, Set-up & Service fee, secure parking and a function manager.`,
            text2: "10 to 60 guests",
          },
          {
            head: "Catering",
            img: "/images/venue/corp-gal-8.jpg",
            text1: `The conference centre offers catering services, including buffet and sit-down
            options, with a wide range of menus to suit your specific dietary requirements.
            The staff is highly professional, attentive, and experienced, ensuring that your
            event runs smoothly from start to finish.`,
            text2: "World-class cuisine",
          },
        ]}
      />

      {/* <SectionFadeIn className={"standard-width gallery-section"}>
        <GalleryBlock
          image1={"/images/venue/corp-gal-1.jpg"}
          image2={"/images/venue/corp-gal-4.jpg"}
          image3={"/images/venue/corp-gal-2.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/venue/corp-gal-5.jpg"}
          image2={"/images/venue/corp-gal-3.jpg"}
          image3={"/images/venue/corp-gal-6.jpg"}
          isRight={true}
        />
      </SectionFadeIn> */}

      {/* <SectionFadeIn className={"standard-width"}>
        <div className={"home-collage"}>
          <img src={"/images/home/home-collage-2.jpg"} />
        </div>
      </SectionFadeIn> */}

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/venue/corp-showcase.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default VenueCorporate;

