
export const PAGE_ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  GALLERY: "/gallery",
  MAIN_VENUE: "/main-venue",
  BOUTIQUE_VENUE: "/boutique-venue",
  CORPORATE: "/corporate-events",
  ACCOMMODATION: "/accommodation",
};

export const LOADING_STATE = {
  NOT_LOADING: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 3,
};