import "./testimonials.css";

import React from "react";
import { IconButton } from "@material-ui/core";
import { FormatQuote, ArrowForward, ArrowBack } from "@material-ui/icons";
import ScrollAnimation from "react-animate-on-scroll";

import SectionFadeIn from "../general/SectionFadeIn";

const styles = {
  quoteIcon: {
    width: 60,
    height: 60,
    color: "#b2c7b9",
  },
};


const Testimonials = ({ testimonials }) => {

  const [currentTestimonial, setCurrentTestimonial] = React.useState(0);

  //Preload all the testimonial images
  React.useEffect(() => {
    testimonials.forEach((testimonial) => {
      const img = new Image();
      img.src = testimonial.img;
    });
  }, [testimonials]);


  return (
   
    <SectionFadeIn className={"standard-width home-testimonials-section"}>
      <div className={"home-testimonials"}>
        {/* <img
          src={"/images/logo-rose.png"}
          className={"testimonials-pre-head"}
        /> */}

        <div className={"home-testimonial"}>
          <ScrollAnimation
            animateIn={"fadeInLeft"}
            animateOnce={true}
            duration={2}
            className={"home-testimonial-img-cont"}
          >
            <img
              src={testimonials[currentTestimonial].img}
              className={"home-testimonial-img"}
            />
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={"fadeInRight"}
            animateOnce={true}
            duration={2}
            className={"home-testimonial-content"}
          >
            <FormatQuote style={styles.quoteIcon} />
            <h3>{testimonials[currentTestimonial].name}</h3>
            <p>{testimonials[currentTestimonial].text}</p>
          </ScrollAnimation>
        </div>
      </div>
      <div className={"home-testimonial-nav"}>
        <IconButton onClick={() => setCurrentTestimonial((currentTestimonial - 1 + testimonials.length) % testimonials.length)}>
          <ArrowBack />
        </IconButton>
        <p>
          {currentTestimonial + 1} / {testimonials.length}
        </p>
        <IconButton onClick={() => setCurrentTestimonial((currentTestimonial + 1) % testimonials.length)}>
          <ArrowForward />
        </IconButton>
      </div>
    </SectionFadeIn>
  );
};

export default Testimonials;