import "./topNav.css";

import React from "react";
import { Link } from "react-router-dom";
import { Button, IconButton, Drawer, Menu, MenuItem, Collapse } from "@material-ui/core";
import { Menu as MenuIcon, Close, ArrowDropDown } from "@material-ui/icons";

import { PAGE_ROUTES } from "../../utils/stateTypes";

// Material UI styles
const styles = {
  menuButton: {
    marginRight: "20px",
    color: "rgb(124, 124, 124)",
  },
  menuFeaturedButton: {
    color: "rgb(124, 124, 124)",
  },
  drawerClose: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  drawerButton: {
    width: "100%",
    padding: "15px",
  },
  chatFab: {
    position: "fixed",
    bottom: 20,
    right: 20,
    backgroundColor: "#25d366",
  },
  chatFabIcon: {
    enableBackground: "new 0 0 90 90",
    width: 25,
    height: 25,
    fill: "#fff",
  },
};

//Set up a function to update isScrolled when scrolling
const handleScroll = (setIsScrolled) => (
  () => {
    const scrolled = window.scrollY > 50;
    setIsScrolled(scrolled);
  }
);


const TopNav = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const closeDrawer = React.useCallback(() => {
    setShowDrawer(false);
    setVenueSubCollapse(false);
  }, []);
  const openDrawer = React.useCallback(() => setShowDrawer(true), []);

  const [isScrolled, setIsScrolled] = React.useState(false);
  React.useEffect(() => {
    let checkIsScrolledFn = setInterval(handleScroll(setIsScrolled), 100);
    return () => {
      clearInterval(checkIsScrolledFn);
    };
  }, []);

  // Top nav submenu
  const [venueSubAnchor, setVenueSubAnchor] = React.useState(null);
  const openVenueSub = React.useCallback((e) => {
    setVenueSubAnchor(e.currentTarget);
  }
  , []);
  const closeVenueSub = React.useCallback(() => {
    setVenueSubAnchor(null);
  }, []);

  // Mobile submenu
  const [venueSubCollapse, setVenueSubCollapse] = React.useState(false);
  const toggleVenueSubCollapse = React.useCallback(() => {
    setVenueSubCollapse(!venueSubCollapse);
  }, [venueSubCollapse]);


  return (
    <nav className={`menu-top ${isScrolled ? "scrolled" : ""}`}>
      <div className={"menu-top-inner standard-width"}>

        <Link
          to={PAGE_ROUTES.HOME}
          className={"menu-top-logo"}
        >
          <img
            src={"/images/logo-2.png"}
            className={"menu-top-logo"}
          />
        </Link>

        <div className={"menu-top-links"}>
          <Link
            to={PAGE_ROUTES.HOME}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Home
            </Button>
          </Link>
          <span className={"menu-top-button"}>
            <Button
              variant={"text"}
              style={styles.menuButton}
              onClick={openVenueSub}
              endIcon={<ArrowDropDown />}
            >
              Venues
            </Button>
          </span>
          {/* Set up the venue submenu */}
          <Menu
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            anchorEl={venueSubAnchor}
            open={Boolean(venueSubAnchor)}
            onClose={closeVenueSub}
            disableScrollLock={true}
          >
            <MenuItem>
              <Link to={PAGE_ROUTES.MAIN_VENUE}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeVenueSub}
                >
                  Main Venue
                </Button>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={PAGE_ROUTES.BOUTIQUE_VENUE}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeVenueSub}
                >
                  Boutique Venue
                </Button>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={PAGE_ROUTES.CORPORATE}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeVenueSub}
                >
                  Corporate Event
                </Button>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to={PAGE_ROUTES.ACCOMMODATION}>
                <Button
                  variant={"text"}
                  style={styles.menuButton}
                  onClick={closeVenueSub}
                >
                  Accommodation
                </Button>
              </Link>
            </MenuItem>
          </Menu>

          <Link
            to={PAGE_ROUTES.ABOUT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              About Us
            </Button>
          </Link>

          <Link
            to={PAGE_ROUTES.GALLERY}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Gallery
            </Button>
          </Link>
          <Link
            to={PAGE_ROUTES.CONTACT}
            className={"menu-top-button"}
          >
            <Button
              variant={"text"}
              style={styles.menuButton}
            >
              Contact Us
            </Button>
          </Link>
          <a
            href={"https://chezcharlene.our-venue.com/"}
            target={"_blank"}
            className={"menu-top-button"}
            rel={"noreferrer"}
          >
            <Button
              variant={"text"}
              style={styles.menuFeaturedButton}
            >
              Get a quote
            </Button>
          </a>
        </div>

        {/* =================== MOBILE MENU =================== */}
        <span className={"mobile-menu-toggle"}>
          <IconButton onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
        </span>
      </div>

      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={closeDrawer}
      >
        <div className={"drawer-inner"}>
          <IconButton
            onClick={closeDrawer} 
            style={styles.drawerClose}
            color={"secondary"}
          >
            <Close />
          </IconButton>
          
          <div>
            <Link to={PAGE_ROUTES.HOME}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Home
              </Button>
            </Link>
            <Button
              variant={"text"}
              color={"secondary"}
              size={"large"}
              style={styles.drawerButton}
              onClick={toggleVenueSubCollapse}
              endIcon={<ArrowDropDown />}
            >
              Venues
            </Button>
            <Collapse
              in={venueSubCollapse}
              timeout={"auto"}
              unmountOnExit
            >
              <Link to={PAGE_ROUTES.MAIN_VENUE}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Main Venue
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.BOUTIQUE_VENUE}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Boutique Venue
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.CORPORATE}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Corporate Event
                </Button>
              </Link>
              <Link to={PAGE_ROUTES.ACCOMMODATION}>
                <Button
                  variant={"text"}
                  color={"secondary"}
                  style={styles.drawerButton}
                  onClick={closeDrawer}
                >
                  Accommodation
                </Button>
              </Link>
            </Collapse>
            <Link to={PAGE_ROUTES.ABOUT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                About Us
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.GALLERY}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Gallery
              </Button>
            </Link>
            <Link to={PAGE_ROUTES.CONTACT}>
              <Button
                variant={"text"}
                color={"secondary"}
                size={"large"}
                style={styles.drawerButton}
                onClick={closeDrawer}
              >
                Contact Us
              </Button>
            </Link>
            <Button
              variant={"text"}
              color={"secondary"}
              size={"large"}
              style={styles.drawerButton}
              href={"https://chezcharlene.our-venue.com/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              Get a quote
            </Button>
          </div>

          <img
            className={"drawer-menu-logo"}
            src={"/images/logo-white.png"}
          />
        </div>
      </Drawer>
      <IconButton
        href={"https://wa.me/27609580350"}
        target={"_blank"}
        style={styles.chatFab}
      >
        <svg
          xmlns={"http://www.w3.org/2000/svg"}
          viewBox={"0 0 90 90"}
          style={styles.chatFabIcon}
          xmlSpace={"preserve"}
        >
          <g>
            <path 
              id={"WhatsApp"}
              d={"M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522 c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982 c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537 c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938 c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537 c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333 c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882 c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977 c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344 c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223 C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"}
            />
          </g>
        </svg>
      </IconButton>
    </nav>
  );
};

export default TopNav;