import "./aboutBlock.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const styles = {
  infoButton: {
    marginTop: 20,
    padding: "10px 20px",
  },
};


const AboutBlock = ({ preHead, head, text, link=null, linkHref=null, linkLabel, image1, image2=null, isSwapped=false }) => {
  return (
   
    <div className={"about-block-section"}>
      {
        (!isSwapped) && (
          <ScrollAnimation
            animateIn={"backInLeft"}
            animateOnce={true}
            duration={2}
            className={"about-block-img"}
          >
            <img src={image1} />
            {
              (image2) && (
                <img
                  src={image2}
                  className={"about-block-img-sub"}
                />
              )
            }
          </ScrollAnimation>
        )
      }
      {
        (isSwapped) && (
          <ScrollAnimation
            animateIn={"backInLeft"}
            animateOnce={true}
            duration={2}
            className={"about-block-img show-when-sm"}
          >
            <img src={image1} />
          </ScrollAnimation>
        )
      }
      <ScrollAnimation
        animateIn={(isSwapped) ? "backInLeft" : "backInRight"}
        animateOnce={true}
        duration={2}
        className={`about-block-content ${(isSwapped) ? "alternate" : ""}`}
      >
        <p className={"about-block-pre-head"}>
          {preHead}
        </p>
        <h2>
          {head}
        </h2>
        <p className={"about-block-text"}>
          {text}
        </p>

        {
          (link) && (
            <Link to={link}>
              <Button
                variant={"outlined"}
                style={styles.infoButton}
                color={"primary"}
              >
                {linkLabel}
              </Button>
            </Link>
          )
        }
        {
          (linkHref) && (
            <Button
              variant={"outlined"}
              style={styles.infoButton}
              color={"primary"}
              href={linkHref}
              target={"_blank"}
            >
              {linkLabel}
            </Button>
          )
        }
      </ScrollAnimation>
      {
        (isSwapped) && (
          <ScrollAnimation
            animateIn={"backInRight"}
            animateOnce={true}
            duration={2}
            className={"about-block-img hide-when-sm"}
          >
            <img src={image1} />
            {
              (image2) && (
                <img
                  src={image2}
                  className={"about-block-img-sub"}
                />
              )
            }
          </ScrollAnimation>
        )
      }
    </div>
  );
};

export default AboutBlock;

