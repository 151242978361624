/* eslint-disable react-hooks/exhaustive-deps */
import "./home.css";

import React from "react";
import { Helmet } from "react-helmet";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import SectionFadeIn from "../general/SectionFadeIn";
import AboutBlock from "../sections/AboutBlock";
import InfoBlocks from "../sections/InfoBlocks";
import Showcase from "../sections/Showcase";
import Contact from "../sections/Contact";
import Testimonials from "../sections/Testimonials";

const BANNER_IMAGES = [
  "/images/home/home-banner-1.jpg",
  "/images/home/home-banner-2.jpg",
  "/images/home/home-banner-3.jpg",
];

const Home = () => {

  const [activeImage, setActiveImage] = React.useState(0);

  //Auto rotate images
  const [intervalItem, setIntervalItem] = React.useState(null);
  React.useEffect(() => {
    if (intervalItem) {
      clearInterval(intervalItem);
    }
    setIntervalItem(setInterval(() => {
      setActiveImage((activeImage + 1) % BANNER_IMAGES.length);
    }, 3000));
    return () => {
      clearInterval(intervalItem);
    };
  }, [activeImage]);

  
  return (
    <main>
      <Helmet>
        <title>Chez Charlene</title>
        <meta
          name={"description"}
          content={"Chez Charlene - 5-star wedding and conference venue in East Pretoria. With world-class cuisince and service, this oasis of beauty and tranquility is regarded as the best venue in Gauteng."}
        ></meta>
      </Helmet>

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <img src={"/images/stars.png"} />
          <h1>Let the beauty of what you<br/>love be what you do</h1>
          <p>An unforgettable 5 star wedding & conference venue</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width"}>
        
        <div className={"home-stars"}>
          <img src={"/images/stars.png"} />
        </div>
      </SectionFadeIn>

      <SectionFadeIn className={"standard-width"}>
        <AboutBlock
          preHead={"WORDS FROM OUR FOUNDER, CHARLENE GEORGIADES"}
          head={"'In Arts Embrace Love Takes Flight'"}
          text={`Life is a fragile beautiful thing. In the heart of passion’s embrace, dreams are woven with delicate grace. 
            In a place called Chez Charlene, our love dances upon the stars above in a timeless and tasteful 
            beautiful maze. With every stroke of art’s sweet muse we create symphonies, and our hearts collide.
            
            Conversations captured, brushes, pens, colours, lines and shapes, our hearts collide. Creating masterpieces, side by side. 
            In Chez Charlene’s venue tender embrace we mould love with every trace. Sculpting and crafting landscapes that are undefiled. 
            Masterpieces unfold canvases of emotions. Pure and bold, in love’s embrace we shall never fail. At Chez Charlene Venue, passion resides. 
            Our souls entwined, symphony echoes day by day.
            
            So, let us paint our story together through whispered words, joy, and wonder. In the sanctuary of Chez Charlene Venue, relationships are born and blossom. 
            And as we create, love poems are written. For all of time.`}
          link={PAGE_ROUTES.CONTACT}
          linkLabel={"Arrange a visit with us"}
          image1={"/images/home/home-info-10.jpg"}
        />
        <AboutBlock
          preHead={"OUR SERVICES AND OFFERINGS"}
          head={"Your Dream brought to life, no detail spared"}
          text={`Our expert team of wedding and event planners will
            completely align with your vision and bring your dream to life.
            We are dedicated to ensuring that every detail is taken care of,
            so that you can relax and focus on what's important - enjoying
            yourself!`}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Build your event"}
          image1={"/images/home/home-info-7.jpg"}
          isSwapped={true}
        />
      </SectionFadeIn>

      <SectionFadeIn className={"standard-width"}>
        <div className={"home-collage"}>
          <img src={"/images/home/home-collage-2.jpg"} />
        </div>
      </SectionFadeIn>

      <Testimonials
        testimonials={[
          {
            img: "/images/testimonial/test-2.jpg",
            name: "Janine & Daryn",
            text: "It is with great pleasure to write this letter with reference on our experience with your team. Myself and my husband as well as guests are extremely pleased with your service. The food was excellent, service from chefs as well as waiters were extraordinary!",
          },
          {
            img: "/images/testimonial/test-3.jpg",
            name: "E.P. and Annelie",
            text: "Please afford us the opportunity to thank you for all the hard work in making our dream come true at your exceptional venue. From the first meeting with Deoné we just knew we had found the people we needed to make it all happen. We did not even bother looking at any other venues.",
          },
          {
            img: "/images/testimonial/test-4.jpg",
            name: "Tanya & Chester",
            text: "Thank you very much for all you did for us, your team is superb, we've been treated so well and couldn't have asked for a more perfect special night, you went beyond our expectations and amazed us. We would most definitely recommend this gorgeous venue.",
          },
          {
            img: "/images/testimonial/test-5.jpg",
            name: "Adam Cheis",
            text: "Your team was so professional, and everything went off so well. The food was delicious, and the decor was simply stunning. The guests were raving about the special dessert. I know this is something that you do every day, but for us it was truly a wonderful experience. Well done!",
          },
          {
            img: "/images/testimonial/test-1.jpg",
            name: "Emmanuel & Tebogo",
            text: "We were so privileged to have had our wedding at Chez Charlene, and grateful for all the advice and planning leading up to our big day. Our guests all said the same thing: it was a very beautiful wedding. The day wasn't without hiccups, but the professionality of your staff, and your kindness and expertise made it all a huge success!",
          },
          {
            img: "/images/testimonial/test-6.jpg",
            name: "The Mfekas",
            text: "We just wanted to take this opportunity to thank you and your amazing team for making the wedding of our dreams. You surpassed anything we could have imagined and hoped for. Thank you for taking the little ideas we had and bringing them to life. Everything was so magical and breathtaking. Both families have been speaking about how absolutely amazing everything was.",
          },
        ]}
      />

      <InfoBlocks
        preHead={"OUR SPACES"}
        head={"Choose Your Venue"}
        blocks={[
          {
            head: "Boutique Venue",
            img: "/images/home/venue-boutique-venue-2.jpg",
            text1: `This chapel seats 100 guests, with all standard décor included in your personalised quotation.
            `,
            text2: "Guest capacity: 10 - 100",
            link: PAGE_ROUTES.BOUTIQUE_VENUE,
            linkLabel: "Find Out More",
          },
          {
            head: "Main Venue",
            img: "/images/home/venue-main-venue-2.jpg",
            text1:  `Main venue embodies romance, elegance and beauty. Décor allows for beautiful photographs.
            `,
            text2: "Guest capacity: 80 - 400",
            link: PAGE_ROUTES.MAIN_VENUE,
            linkLabel: "Find Out More",
          },
          {
            head: "Corporate Venue",
            img: "/images/home/venue-corporate-venue-2.jpg",
            text1: `Five-star and Abia awards winner – best venue in Gauteng, it is an oasis of beauty and tranquility.
            `,
            text2: "Guest capacity: 10 - 350",
            link: PAGE_ROUTES.CORPORATE,
            linkLabel: "Find Out More",
          },
        ]}
      />

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/home/showcase-4.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default Home;

