import "./about.css";

import React from "react";
import { Helmet } from "react-helmet";

import SectionFadeIn from "../general/SectionFadeIn";
import Contact from "../sections/Contact";
import AboutBlock from "../sections/AboutBlock";
import Showcase from "../sections/Showcase";
import { PAGE_ROUTES } from "../../utils/stateTypes";
//import Header from "../sections/Header";

const BANNER_IMAGES = [
  "/images/about/about-banner-1.jpg",
];

const About = () => {

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>About | Chez Charlene</title>
        <meta
          name={"description"}
          content={"Chez Charlene - 5-star wedding and conference venue in East Pretoria. With world-class cuisince and service, this oasis of beauty and tranquility is regarded as the best venue in Gauteng."}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "About" },
        ]}
        head={"About"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Only from the heart<br/>can you touch the sky</h1>
          <p>Find out about our venue and our team</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width about-info-section"}>
        <AboutBlock
          preHead={"5 STAR WEDDING & CONFERENCE VENUE"}
          head={"Chez Charlene Venue in Pretoria, Gauteng"}
          text={`Chez Charlene is a Five-star Wedding & Conference Venue, in Pretoria, Gauteng, an oasis of beauty and tranquility. 
            Nestled amidst the serene countryside, Chez Charlene Wedding & Conference Venue, Pretoria, Gauteng exudes an unparalleled sense 
            of refined elegance that awakens the senses and inspires the soul. This bespoke haven is a celebration of all things luxurious, 
            where every detail is carefully curated to create a one-of-a-kind experience that is as unique as it is unforgettable. As you wander 
            the enchanting grounds, the gentle neighs of horses roaming in the distance transport you to a simpler, more peaceful time. The sprawling 
            estate is a true feast for the eyes, with its lush gardens and rolling hills providing the perfect backdrop for a day of celebration.`}
          link={PAGE_ROUTES.CONTACT}
          linkLabel={"Book a viewing"}
          image1={"/images/about/about-info-1.jpg"}
        />
        <AboutBlock
          preHead={"A UNIQUE, ONE-STOP SHOP"}
          head={"Where your every need is met and exceeded"}
          text={`This is a place where dreams are brought to life, memories are made, and love is celebrated in all its splendor. We have taken 
            measures to ensure uninterrupted power supply during power outages by installing both a backup generator and a solar panel system. 
            This allows us to continue functioning even when there are disruptions to the main power supply. Chez Charlene 5 Star Wedding & Conference 
            Venue, Pretoria, Gauteng is a – “One-Stop-Shop “The Phrase below emphasizes the idea that the provides takes a comprehensive approach to meet 
            its customers’ needs! A Holistic Provider – We here at Chez Charlene use your love story as Inspiration! This wedding and conference venue 
            exudes luxury, yet is affordable!`}
          link={PAGE_ROUTES.CONTACT}
          linkLabel={"Book a viewing"}
          image1={"/images/about/about-info-10.jpg"}
          isSwapped={true}
        />
        <AboutBlock
          preHead={"ENDULGE ALL OF YOUR SENSES"}
          head={"Enjoy our amazing wedding cuisine"}
          text={`We here at Chez Charlene 5 Star Wedding & Conference Venue, 
            Pretoria, Gauteng use your love story as Inspiration! But the true star of the show is the cuisine. From the first bite to the last, every dish 
            is a masterpiece, expertly crafted using only the finest ingredients and presented with an artist’s eye for detail. The culinary delights are 
            sure to leave your taste buds dancing with delight and your heart full of contentment. So, if you’re seeking an elegant 5-star elegant country 
            venue that is as exquisite as it is welcoming, where everything can be designed and arranged for you, look no further than Chez Charlene 5-star 
            Wedding and Conference Venue, Pretoria, Gauteng.`}
          link={PAGE_ROUTES.CONTACT}
          linkLabel={"Book a viewing"}
          image1={"/images/about/about-info-3.jpg"}
        />
        <AboutBlock
          preHead={"CHIEF EXECUTIVE OFFICER & OWNER"}
          head={"Charlene Georgiades"}
          text={`Charlene has always had a passion for arts and culture! Every aspect of Chez Charlene’s 5 Star Venue reflects the heart 
            and soul of Charlene Georgiades. At its core, elegance is the characteristic of being “graceful and stylish in appearance or manner.” 
            Elegance is attractive, mature, and sophisticated while still being approachable. Elegance, particularly in design and personal style, 
            is one of the first qualities that outsiders notice, and elegance certainly causes a favourable and lasting first impression. 
            It is this Breath-taking Elegant Chez Charlene 5 A Star Wedding Venue on a beautiful Country Estate with horses roaming that resonates 
            with ones’ soul recognising the love Charlene Georgiades had been dedicated to! Over the years, Charlene expanded her vision, building 
            the Intimate Chez Charlene Boutique Venue and chapel for more intimate weddings and events. All the hard work she put into this piece of 
            art really shows! The style truly evokes so many emotions and brings a smile to one’s face!`}
          image1={"/images/about/about-info-4.jpg"}
          isSwapped={true}
        />
      </SectionFadeIn>

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/about/about-showcase-3.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>
      
      <Contact />
    </main>
  );
};

export default About;

