import "./infoBlocks.css";

import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import SectionFadeIn from "../general/SectionFadeIn";


const styles = {
  button: {
    padding: "10px 20px",
    position: "absolute",
    bottom: 40,
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const InfoBlocks = ({ blocks, preHead=null, head, preText=null }) => {
  return (
    <SectionFadeIn className={"standard-width"}>
      <div className={"info-blocks"}>
        {
          (preHead !== null) && (
            <p className={"section-head-pre"}>{preHead}</p>
          )
        }
        <h2 className={"section-head"}>{head}</h2>
        {
          (preText !== null) && (
            <p className={"standard-width-half"}>{preText}</p>
          )
        }

        <div className={"info-blocks-grid"}>
          {
            blocks.map((block, index) => (
              <ScrollAnimation
                key={index}
                animateIn={"backInUp"}
                animateOnce={true}
                duration={1}
                delay={index * 600}
                className={`info-block ${((index % 2) === 0) ? "white" : "gold"}`}
              >
                <img
                  src={block.img}
                  className={`info-block-img alt-${index + 1}`}
                />
                <p className={"text-2-header"}>{block.text2}</p>
                <h3>{block.head}</h3>
                <p>
                  {block.text1}
                </p>
                <br/><br/><br/><br/>
                {
                  (block.link) && (
                    <Link to={block.link}>
                      <Button
                        variant={"outlined"}
                        color={((index % 2) === 0) ? "primary" : "secondary"}
                        style={styles.button}
                      >
                        {block.linkLabel}
                      </Button>
                    </Link>
                  )
                }
              </ScrollAnimation>
            ))
          }
        </div>
      </div>
    </SectionFadeIn>

  );
};

export default InfoBlocks;

