import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(122, 116, 106)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "Noto Sans",
    allVariants: {
      "@media (max-width:1200px)": {
        fontSize: "0.9em",
      },
      "@media (max-width:800px)": {
        fontSize: "0.8em",
      },
    },
  },
  overrides: {
    //Remove rounded corners from buttons
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
});

export default theme;