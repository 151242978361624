import "./pageContact.css";

import React from "react";
import { Button, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { Helmet } from "react-helmet";

import { LOADING_STATE } from "../../utils/stateTypes";
import SectionFadeIn from "../general/SectionFadeIn";
// import Header from "../sections/Header";

const styles = {
  inputHalf: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
    marginRight: 20,
  },
  inputHalf2: {
    width: "calc(50% - 10px)",
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
    textAlign: "left",
  },
  submit: {
    padding: 10,
  },
};

const BANNER_IMAGES = [
  "/images/contact/contact-banner-1.jpg",
];

const sendMessage = async (name, email, type, date, pax, message, setSendState) => {
  setSendState(LOADING_STATE.LOADING);
  const response = await fetch("/api/Contact/SendContactMessage", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      date,
      pax,
      type,
      message,
    }),
  });
  if (response.ok) {
    setSendState(LOADING_STATE.LOADED);
  }
  else {
    setSendState(LOADING_STATE.ERROR);
  }
};


const PageContact = () => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("Viewing the venue");
  const [date, setDate] = React.useState(null);
  const [pax, setPax] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sendState, setSendState] = React.useState(LOADING_STATE.NOT_LOADING);

  let submitLabel = "Send";
  if (sendState === LOADING_STATE.LOADING) {
    submitLabel = <CircularProgress size={25} />;
  }
  else if (sendState === LOADING_STATE.LOADED) {
    submitLabel = "Sent!";
  }
  else if (sendState === LOADING_STATE.ERROR) {
    submitLabel = "Retry";
  }

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Contact | Chez Charlene</title>
        <meta
          name={"description"}
          content={"Get in touch, or book a viewing. We can't wait to see you!"}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Contact" },
        ]}
        head={"Contact"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Love is the only flower that grows and blossoms without the aid of the seasons</h1>
          <p>We can't wait to meet you!</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width contact-heading"}>
        <h1>Unleash the beauty of your special day at our enchanting venue. Contact us now to turn your wedding dreams into reality.</h1>
      </SectionFadeIn>

      <SectionFadeIn className={"standard-width contact-details-section"}>
        <div className={"contact-details"}>
          <h2>Contact</h2>
          <table>
            <tbody>
              <tr>
                <td><a href={"tel:0128020791"}>+27 12 802 0791</a></td>
              </tr>
              <tr>
                <td><a href={"tel:0609580350"}>+27 60 958 0350</a></td>
              </tr>
              <tr>
                <td><a href={"mailto:info@chezcharlene.co.za"}>info@chezcharlene.co.za</a></td>
              </tr>
              <tr>
                <td><a href={"https://maps.app.goo.gl/wHD8wbGqR2BYcCHg9"}>R223, Plot 22 Mooiplaats, Pretoria East 0032</a></td>
              </tr>
            </tbody>
          </table>
          
          <h2>Our Hours</h2>
          <table>
            <tbody>
              <tr>
                <td>Monday</td>
                <td>7:30am - 16:30pm</td>
              </tr>
              <tr>
                <td>Tuesday</td>
                <td>7:30am - 16:30pm</td>
              </tr>
              <tr>
                <td>Wednesday</td>
                <td>7:30am - 16:30pm</td>
              </tr>
              <tr>
                <td>Thursday</td>
                <td>7:30am - 16:30pm</td>
              </tr>
              <tr>
                <td>Friday</td>
                <td>7:30am - 16:30pm</td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>8:00am - 1:00pm</td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>By appointment only</td>
              </tr>
            </tbody>
          </table>
        </div>

        <iframe
          className={"map"}
          src={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d114948.9163779101!2d28.399383!3d-25.798505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955c72186e4ccd%3A0x34fcc24fe51ff3f8!2sChez%20Charlene%20Wedding%20Venue!5e0!3m2!1sen!2sus!4v1719413532664!5m2!1sen!2sus"}
          allowFullScreen={true}
          loading={"lazy"}
          referrerPolicy={"no-referrer-when-downgrade"}
        />
      </SectionFadeIn>

      <SectionFadeIn className={"standard-width contact-form-section"}>
        <form
          className={"contact-form"}
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage(name, email, type, date, pax, message, setSendState);
          }}
        >
          <h2>Get in touch</h2>
          <TextField
            label={"Name"}
            variant={"standard"}
            color={"primary"}
            style={styles.inputHalf}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label={"Email"}
            variant={"standard"}
            color={"primary"}
            style={styles.inputHalf2}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FormControl
            variant={"standard"}
            color={"primary"}
            style={styles.input}
            fullWidth
            required
          >
            <InputLabel>I am enquiring about</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              <MenuItem value={"Viewing the venue"}>Viewing the venue</MenuItem>
              <MenuItem value={"General enquiry"}>General enquiry</MenuItem>
            </Select>
          </FormControl>
          {
            (type === "Viewing the venue") && (
              <DatePicker
                label={"Event date"}
                inputVariant={"standard"}
                color={"primary"}
                variant={"dialog"}
                DialogProps={{
                  disableScrollLock: true,
                }}
                style={styles.input}
                value={date}
                onChange={(e) => setDate(e)}
                minDate={new Date()}
                fullWidth
                required
              />
            )
          }
          {
            (type === "Viewing the venue") && (
              <TextField
                label={"Approximate number of guests"}
                variant={"standard"}
                color={"primary"}
                style={styles.input}
                fullWidth
                value={pax}
                onChange={(e) => setPax(e.target.value)}
                required
              />
            )
          }
          
          {
            (type === "General enquiry") && (
              <TextField
                label={"How can we help?"}
                variant={"standard"}
                color={"primary"}
                style={styles.input}
                fullWidth
                multiline
                minRows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            )
          }
          <Button
            variant={"outlined"}
            color={"primary"}
            type={"submit"}
            fullWidth
            style={styles.submit}
            disabled={(sendState === LOADING_STATE.LOADING || sendState === LOADING_STATE.LOADED)}
          >
            {submitLabel}
          </Button>
        </form>
      </SectionFadeIn>
    </main>
  );
};

export default PageContact;

