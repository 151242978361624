import "./pageGallery.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import React from "react";
import { Helmet } from "react-helmet";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import SectionFadeIn from "../general/SectionFadeIn";
import Contact from "../sections/Contact";
// import { PAGE_ROUTES } from "../../utils/stateTypes";
// import Header from "../sections/Header";

const BANNER_IMAGES = [
  "/images/gallery/gallery-banner-1.jpg",
];

const PHOTOS = [
  { src: "/images/gallery/gal-001.jpg", width: 1800, height: 1205 },
  { src: "/images/gallery/gal-002.jpg", width: 1800, height: 1205 },
  { src: "/images/gallery/gal-003.jpg", width: 1800, height: 1205 },
  { src: "/images/gallery/gal-004.jpg", width: 1800, height: 993 },
  { src: "/images/gallery/gal-005.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-006.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-007.jpg", width: 1800, height: 1199 },
  { src: "/images/gallery/gal-008.jpg", width: 1800, height: 1168 },
  { src: "/images/gallery/gal-009.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-010.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-011.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-012.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-013.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-014.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-015.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-016.jpg", width: 1800, height: 1202 },
  { src: "/images/gallery/gal-017.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-018.jpg", width: 1800, height: 1386 },
  { src: "/images/gallery/gal-019.jpg", width: 1800, height: 1202 },
  { src: "/images/gallery/gal-020.jpg", width: 1800, height: 1202 },
  { src: "/images/gallery/gal-021.jpg", width: 1800, height: 1202 },
  { src: "/images/gallery/gal-022.jpg", width: 1800, height: 1097 },
  { src: "/images/gallery/gal-023.jpg", width: 1800, height: 1132 },
  { src: "/images/gallery/gal-024.jpg", width: 1800, height: 1139 },
  { src: "/images/gallery/gal-025.jpg", width: 1800, height: 1440 },
  { src: "/images/gallery/gal-026.jpg", width: 1800, height: 1144 },
  { src: "/images/gallery/gal-027.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-028.jpg", width: 1800, height: 1197 },
  { src: "/images/gallery/gal-029.jpg", width: 1800, height: 1197 },
  { src: "/images/gallery/gal-030.jpg", width: 1800, height: 1197 },
  { src: "/images/gallery/gal-031.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-032.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-033.jpg", width: 1800, height: 2699 },
  { src: "/images/gallery/gal-034.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-035.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-036.jpg", width: 1800, height: 2698 },
  { src: "/images/gallery/gal-037.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-038.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-039.jpg", width: 1800, height: 2700 },
  // { src: "/images/gallery/gal-040.jpg", width: 1800, height: 2700 },
  // { src: "/images/gallery/gal-041.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-042.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-043.jpg", width: 1800, height: 2699 },
  { src: "/images/gallery/gal-044.jpg", width: 1800, height: 2699 },
  { src: "/images/gallery/gal-045.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-046.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-047.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-048.jpg", width: 1800, height: 2700 },
  { src: "/images/gallery/gal-049.jpg", width: 1800, height: 1200 },
  { src: "/images/gallery/gal-050.jpg", width: 1000, height: 1503 },
  { src: "/images/gallery/gal-051.jpg", width: 1000, height: 1400 },
  { src: "/images/gallery/gal-052.jpg", width: 1024, height: 683 },
  { src: "/images/gallery/gal-053.jpg", width: 1024, height: 1535 },
];


const PageGallery = () => {

  const [lightboxIndex, setLightboxIndex] = React.useState(-1);

  const photos = React.useMemo(() => {
    //randomize the order of the photos
    const photos = PHOTOS.slice();
    for (let i = photos.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [photos[i], photos[j]] = [photos[j], photos[i]];
    }
    return photos;
  }, []);

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Gallery | Chez Charlene</title>
        <meta
          name={"description"}
          content={"Get an idea of how your dream can become a reality at Chez Charlene with these amazing photos."}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Gallery" },
        ]}
        head={"Gallery"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Close your eyes,<br/>fall in love, stay here</h1>
          <p>Take a look through some of our previous events!</p>
        </div>
      </header>
      
      <SectionFadeIn className={"page-gallery-section"}>
        <div className={"gal-outer-cont standard-width"}>
          <div className={"tab-gallery-cont active"}>
            <h1>Brand New Gallery Launching in 2025</h1>
            <p>We've been busy! Check back soon to see our brand new gallery and get a taste of our venues!</p>

            {/* <PhotoAlbum
              layout={"columns"}
              columns={(containerWidth) => {
                if (containerWidth <= 500) {
                  return 1;
                }
                return 3;
              }}
              photos={photos}
              spacing={10}
              onClick={(event, photo, index) => setLightboxIndex(index)}
            />

            <Lightbox
              open={(lightboxIndex >= 0)}
              index={lightboxIndex}
              close={() => setLightboxIndex(-1)}
              slides={photos}
              animation={{
                swipe: 200,
              }}
              plugins={[Thumbnails]}
              noScroll={{
                disabled: true,
              }}
            /> */}
          </div>
        </div>
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default PageGallery;

