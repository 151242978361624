import "./venue.css";

import React from "react";
import { Helmet } from "react-helmet";

import SectionFadeIn from "../general/SectionFadeIn";
// import { PAGE_ROUTES } from "../../utils/stateTypes";
// import Header from "../sections/Header";
import Contact from "../sections/Contact";
import GallerySlider from "../sections/GallerySlider";
import InfoBlocks from "../sections/InfoBlocks";
// import GalleryBlock from "../sections/GalleryBlock";
import Showcase from "../sections/Showcase";

const BANNER_IMAGES = [
  "/images/venue/mv-banner-1.jpg",
];

const VenueMain = () => {

  const [activeImage] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Main Venue | Chez Charlene</title>
        <meta
          name={"description"}
          content={"Our Main Venue embodies romance, elegance and beauty. The décor will leave your guests breathless and allows for the most beautiful photographs. 80 - 400 guests"}
        ></meta>
      </Helmet>

      {/* <Header
        breadcrumbs={[
          { text: "Home", link: PAGE_ROUTES.HOME },
          { text: "Main Venue" },
        ]}
        head={"Main Venue"}
      /> */}

      <SectionFadeIn className={"banner-spinner"}>
        {
          BANNER_IMAGES.map((img, index) => (
            <img
              key={index}
              src={img}
              className={`banner-img ${index === activeImage ? "active" : ""}`}
            />
          ))
        }
      </SectionFadeIn>

      <header className={"home-header"}>
        <div className={"standard-width"}>
          <h1>Love is the bridge<br/>between you and everything</h1>
          <p>Discover our range of perfect venues that meet every requirement</p>
        </div>
      </header>

      <SectionFadeIn className={"standard-width venue-heading"}>
        <h1>5 Star Wedding & Conference Venue</h1>
      </SectionFadeIn>

      <GallerySlider
        images={[
          "/images/venue/main-new-8.jpg",
          "/images/venue/main-new-9.jpg",
          "/images/venue/main-new-2.jpg",
        ]}
        preText={`
          Chez Charlene 5 Star Wedding & Conference Venue, Pretoria, Gauteng (capacity-350x pax round tables, 400x pax banquet style with dancefloor), offers majestic chandeliers, full in-house state-of-the-art sound and lighting equipment, wooden dance floor, teak bar, cigar lounge, patios, air-conditioned and a separate buffet station.
          The main venue chapel embodies romance, elegance, and beauty. Décor allows photographers to take photos in style for timeless memories. This elegant chapel seats up to 350pax and is fully air-conditioned and equipped with sound allowing our sound engineer smooth-running service on all levels.
        `}
      />

      <InfoBlocks
        head={"Exquisite & Luxurious"}
        blocks={[
          {
            head: "Chapel",
            img: "/images/venue/main-new-3.jpg",
            text1:  `The Main Venue chapel embodies romance, elegance and beauty. The
            timeless décor allows for beautiful photos that will capture the moment forever.
            The elegant chapel seats up to 350 and is fully air-conditioned, and equipped with
            sound.`,
            text2: "Elegant & air-conditioned",
          },
          {
            head: "Reception",
            img: "/images/venue/main-new-11.jpg",
            text1: `Beneath a constellation of crystal
            chandeliers, the wedding reception hall unfolds in a symphony of elegance, where
            every gleam and shadow dances to the rhythm of joyous celebration. The light
            cascades, touching each table with a soft glow, as if the stars themselves
            have descended to bless the union.`,
            text2: "Crystal chandeliers",
          },
          {
            head: "Gardens",
            img: "/images/venue/main-new-7.jpg",
            text1: `Chez Charlene boasts the grandeur of its flawless architecture coupled
            with picturesque gardens and lavish fields where horses graze and gallop - all the
            ingredients to create lasting memories. This 27 hectare private estate offers
            endless possibilities for romantic photos.`,
            text2: "Incredible photo opportunities",
          },
        ]}
      />

      {/* <SectionFadeIn className={"standard-width gallery-section"}>
        <GalleryBlock
          image1={"/images/venue/mv-gal-7.jpg"}
          image2={"/images/venue/mv-gal-4.jpg"}
          image3={"/images/venue/mv-gal-6.jpg"}
          isReversed={true}
        />
        <GalleryBlock
          image1={"/images/venue/mv-gal-2.jpg"}
          image2={"/images/venue/mv-gal-3.jpg"}
          image3={"/images/venue/mv-gal-5.jpg"}
          isRight={true}
        />
      </SectionFadeIn> */}

      <SectionFadeIn className={"standard-width"}>
        <div className={"home-collage"}>
          <img src={"/images/venue/main-collage-1.jpg"} />
        </div>
      </SectionFadeIn>

      <SectionFadeIn className={"home-showcase-section"}>
        <Showcase
          preHead={"BUILD YOUR EVENT"}
          head1={"Get an Instant Quote"}
          text={"Our online event builder takes you through the steps of creating your perfect event."}
          linkHref={"https://chezcharlene.our-venue.com/"}
          linkLabel={"Use Our Event Builder"}
          image={"/images/venue/mv-showcase.jpg"}
          isGold={false}
          swapped={true}
        />
      </SectionFadeIn>

      <Contact />
    </main>
  );
};

export default VenueMain;

