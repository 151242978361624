/* eslint-disable react-hooks/exhaustive-deps */
import "./css/normalize.css"; //CSS reset
import "./css/site.css";
import "animate.css/animate.compat.css";

import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { PAGE_ROUTES } from "../utils/stateTypes";
import Page404 from "./home/404";
import ScrollToTop from "./general/ScrollToTop";
import TopNav from "./home/TopNav";
import Footer from "./home/Footer";
import Home from "./home/Home";
import VenueMain from "./venue/VenueMain";
import VenueBoutique from "./venue/VenueBoutique";
import VenueCorporate from "./venue/VenueCorporate";
import Accommodation from "./venue/Accommodation";
import About from "./about/About";
import PageContact from "./contact/PageContact";
import PageGallery from "./gallery/PageGallery";


const App = () => {
  let location = useLocation();

  return (
    <main>
      <ScrollToTop />
      <TopNav />
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"fade"}
          timeout={300}
        >
          <Switch location={location}>
            <Route
              exact
              path={PAGE_ROUTES.HOME}
              component={Home}
            />
            <Route
              exact
              path={PAGE_ROUTES.MAIN_VENUE}
              component={VenueMain}
            />
            <Route
              exact
              path={PAGE_ROUTES.BOUTIQUE_VENUE}
              component={VenueBoutique}
            />
            <Route
              exact
              path={PAGE_ROUTES.CORPORATE}
              component={VenueCorporate}
            />
            <Route
              exact
              path={PAGE_ROUTES.ACCOMMODATION}
              component={Accommodation}
            />
            <Route
              exact
              path={PAGE_ROUTES.ABOUT}
              component={About}
            />
            <Route
              exact
              path={PAGE_ROUTES.CONTACT}
              component={PageContact}
            />
            <Route
              exact
              path={PAGE_ROUTES.GALLERY}
              component={PageGallery}
            />
            <Route
              component={Page404}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </main>
  );
};

export default App;